.h1{
    font-size: 3.5rem;
    font-weight: 500;
    font-family: $font;
    color: $font-color;
    line-height: 1.3;
}
.h2 {
    font-size: 2.5rem;
    font-weight: 400;
    font-family: $font;
    color: white;
}

.h3 {
    font-size: 2rem;
    font-weight: 400;
    font-family: $font;
    color: $primary-color;
    line-height: 0.4;
    text-transform: uppercase;
}

.p {
    font-size: 22px;
    font-family: $font;
    color: $font-color !important; 
}
@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-9{
    width: 100%;
    height: auto;
    position: relative;
    background-color: $secondry-color;
    margin:0 auto;
    z-index: 900;

    .container {
        width: 80%;
        height: 675px;
        margin: 0 auto;
        position: relative;
        padding-top: 40px;
        @include media('<=991px') {
            padding-top: 50px;
        }
        .h1 {
            color: white !important;
            text-align: center;
        }
        .p {
            text-align: center;
            color: white !important;
        }


        .infos {
            width: 70%;
            margin:0 auto;
            height: 134px;

            .info {
                width: 50%;
                float: left;
                .icon {
                    margin-right: 15px;
                    width: 90px;
                    height: 90px;
                    background-color: white;
                    float: left;
                    border-radius: 100px;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
                    @include centerItems();
                }

                .text {
                     color: white;
                     margin-top: 10px;
                     h2 {
                         font-size: 25px;
                     }

                     label {
                         font-size: 20px;
                     }
                }
            }
        }

        .form {
            width: 90%;
            height: 350px;
            margin: 0 auto;
            background-color: white;
            border-radius: 32px;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
            padding: 30px;

            @include media('<=991px') {
                width: 100%;
                height: 900px;
            }

            .Item {
                width: 28%;
                float: left;
                margin-right: 40px;
                margin-top: 19px;
                @include media('<=991px') {
                    width: 100%;
                }

                &:nth-child(6n) {
                    .btn-customize {
                        background-color: $secondry-color;
                        border-radius: 100px;
                        margin-top: -28px;
                        position: relative;
                        text-align: left !important;
                        top: 66px;
                        font-size: 16px;
                        &:hover {
                            cursor: pointer;
                            background-color: $secondry-color-hover;
                        }
                    }
                    @include media('<=991px') {
                        .btn-customize {
                            padding: 1.7rem 5.5rem;
                        }
                    }
                }

                &:last-child{
                    width: 100%;
                    clear: both;
                    label {
                        margin-left: 15px;
                    }
                }
            }
        }

        .content {
            width: 27%;
            height: 230px;
            float: left;
            margin-top: 60px;
            background-color: white;
            border-radius: 64px;
            padding: 37px ;
            margin-right: 17px;
            clear: both;
            .icon {
                width: 122px;
                height: 122px;
                background-color: white;
                border-radius: 100%;
                margin-right:15px;
                @include centerItems();
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
            }

            .text {
                .p {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
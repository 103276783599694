$primary-color:#58a596;
$primary-color-opacity:rgba(88,165,150,0.1);
$primary-color-hover:#418477;
$secondry-color:#ed8e88;
$secondry-color-hover:#B96A65;
$secondry-color-opacity:rgba(237,142,136,0.1);
$font-color:#273e4f;
$btn-font-color:#ffffff;


/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

$font: 'Poppins', sans-serif;

/* Layout */
$width : 1440px;
@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-7{
    position: relative;
    margin-top: 80px;
    margin:0 auto;



    .container {
        height:auto;
        margin: 0 auto;
        position: relative;
        @include centerItems();
        .content {
            @include media('<=991px') {
                margin-top: 50px;
            }
            .h3 {
                color: $secondry-color;
            }

            .ImgContent {
                img {
                    width: 95%;
                }
            }
        }
    }

}
@import './breakpoints/breakpoints.scss';

.section-1{
    .container {
        padding-top: 160px; 
        height: 750px;
        margin: 0 auto;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
            width: 50%;
            margin: 0 auto;
            z-index: 10;
            img {
                width: 100%;
            }
        }
    }

    .bg{
        width: 50%;
        position: absolute !important;
        top: 0;
        right: 0;
        z-index: 2;
    }
}


@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-6{
    margin-top: 80px;
    margin:0 auto;
    background-color: $secondry-color-opacity;

    .container {
        height:auto;
        margin: 0 auto;
        @include centerItems();
        @include media('<=991px') {
            margin-top: 100px;
            padding: 30px 0px;
            height: auto;
            text-align: center;
        }
        .content {
            z-index: 10;
            .btn-customize {
                background-color: $secondry-color;
            }

            .ImgContent {
                img {
                    width: 100%;
                    margin-top: 10px;
                }
                
            }
        }
    }

}
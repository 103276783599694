@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}

.btn{
    padding: 1.2rem 1.8rem;
    background: $primary-color;
    border-radius: 100px;
    color: $btn-font-color !important;
    position: relative;
    font-weight: 400;

    img {
        width: 20px !important;
        position: relative;
        top:4px;
        left: 10px;
    }
}

.btn:hover{
    background: $primary-color-hover;
    cursor: pointer;
}


.btn-outline{
    width: 222px;
    padding: 1rem 1rem;
    background: white;
    border-radius: 34px;
    color: $primary-color !important;
    border: 1px solid $primary-color;
    margin-right: 32px;
}

.btn-outline:hover{
    background: #F5F5F5;
    cursor: pointer;
}

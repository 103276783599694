@import './breakpoints/breakpoints.scss';

@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}


.header {
    width: 100%;
    margin:0 auto;
    top: 0;
    padding: 30px 0px 25px 0px;
    font-family: $font;
    z-index: 1000;
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
    /* MOBILE */
    @include media('<=phone') {
        width: 100%;
    }
    /* END MOBILE */

    /* TABELT */
    @include media('>phone','<=tablet') {
        width: 100%;
        margin: 0 auto;
        display: block;

    }/* END TABELT */


    .container {
        width: 100%;
        margin: 0 auto;

        /* MOBILE */
        @include media('<=phone') {
            width: 100%;
            margin: 0 auto;
            display: block;
        } /* END MOBILE */

        /* TABELT */
        @include media('>phone','<=tablet') {
            width: 100%;
            margin: 0 auto;
            display: block;

        }/* END TABELT */

        /* TABELT */
        @include media('>tablet','<=desktop') {
            width: 100%;
            margin: 0 auto;
            display: block;

        }/* END TABELT */
    }

    .logo {
        float: left;
        /* MOBILE */
        @include media('<=phone') {
            width: 155px;
        }
        /* END MOBILE */
    }

    .btnMenu, .menuModal {
        display: none;
    }

    .hidden {
        display: none;
    }


    nav{
        width: 111% !important;
        /* MOBILE */
        @include media('<=phone') {
            display: none;
        }
        /* END MOBILE */

        /* TABELT */
        @include media('>phone','<=tablet') {
            display: none;

        }/* END TABELT */

        @include media('<=desktop') {
            display: none;
        } 

        @include media('<=1399px') {
            .btn-outline{
                margin-left:15px;
            }
        }

        a{
            text-decoration: none;
            text-align: left;
            margin-left: 32px;
            color: $font-color;
            @include media('<=1399px') {
                margin-left: 15px;
            }
        }
        a:hover {
            color: $primary-color;
        }

        .is-active {
            position: relative;
            font-weight: bold;
            color: $primary-color; 
            padding-bottom: 4px;
            border-bottom: 3px solid $primary-color;
            span {
                width: 10px;
                height: 10px;
                margin: 0 auto;
                background-color: $primary-color;
                position: absolute;
                top: -15px;
                left: 50%;
                border-radius: 100px;
            }
        }

        .underline {
            border-bottom: 3px solid $primary-color;
        }

        :last-child {
            margin-left: 0px;
        }
    }

    /* MOBILE */
        @include media('<=phone') {
            .btnMenu {
                width: 50px;
                height: 50px;
                border-radius: 8px;
                background-color: $primary-color-opacity;
                @include centerItems();
                float: right;
                margin-right: 10px;
            }

            .btnClose {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: $primary-color-opacity;
                text-align: center;
                @include centerItems();
                margin-right: 10px;
            }
        
            .menuModal {
                display: block;
                width: 100%;
                height: 100%;
                position: fixed;
                background-color:white ;
                @include centerItems();
                opacity: 0.9;
                ul {
                    li {
                        list-style: none;
                        font-family: $font;
                        padding: 10px;
                        color: $font-color;
                        font-size: 18px;
                        text-align: center;
                        @include centerItems();
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }

                .is-active {
                    position: relative;
                    font-weight: bold;
                    color: $primary-color; 
                    border-bottom: 3px solid $primary-color;
                    span {
                        width: 10px;
                        height: 10px;
                        margin: 0 auto;
                        background-color: $primary-color;
                        position: absolute;
                        top: -15px;
                        left: 50%;
                        border-radius: 100px;
                    }
                }
            }
            
        }
    /* END MOBILE */

    /* TABELT */
    @include media('>phone','<=tablet') {
        .btnMenu {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            background-color: $primary-color-opacity;
            @include centerItems();
            float: right;
            margin-right: 10px;
        }

        .btnClose {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: $primary-color-opacity;
            text-align: center;
            @include centerItems();
            margin-right: 10px;
        }
    
        .menuModal {
            display: block;
            width: 100%;
            height: 100%;
            position: fixed;
            background-color:white ;
            @include centerItems();
            opacity: 0.9;
            ul {
                li {
                    list-style: none;
                    font-family: $font;
                    padding: 10px;
                    color: $font-color;
                    font-size: 18px;
                    text-align: center;
                    @include centerItems();
                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .is-active {
                position: relative;
                font-weight: bold;
                color: $primary-color; 
                border-bottom: 3px solid $primary-color;
                span {
                    width: 10px;
                    height: 10px;
                    margin: 0 auto;
                    background-color: $primary-color;
                    position: absolute;
                    top: -15px;
                    left: 50%;
                    border-radius: 100px;
                }
            }
        }
    }/* END TABELT */


        /* TABELT */
        @include media('<=desktop') {
            .btnMenu {
                width: 50px;
                height: 50px;
                border-radius: 8px;
                background-color: $primary-color-opacity;
                @include centerItems();
                float: right;
                margin-right: 10px;
            }
    
            .btnClose {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: $primary-color-opacity;
                text-align: center;
                @include centerItems();
                margin-right: 10px;
            }
        
            .menuModal {
                display: block;
                width: 100%;
                height: 100%;
                position: fixed;
                background-color:white ;
                @include centerItems();
                opacity: 0.9;
                ul {
                    li {
                        list-style: none;
                        font-family: $font;
                        padding: 10px;
                        color: $font-color;
                        font-size: 18px;
                        text-align: center;
                        @include centerItems();
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }
    
                .is-active {
                    position: relative;
                    font-weight: bold;
                    color: $primary-color; 
                    border-bottom: 3px solid $primary-color;
                    span {
                        width: 10px;
                        height: 10px;
                        margin: 0 auto;
                        background-color: $primary-color;
                        position: absolute;
                        top: -15px;
                        left: 50%;
                        border-radius: 100px;
                    }
                }
            }
        }/* END TABELT */
}
.section-2{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;


    

    .container {
        height: 950px;
        margin-top: 180px;

        @include media('<=991px') {
            margin-top: 580px;
            .conent-s-2 {
                margin-top: 50px;
            }
        }

        

        .content {
            width: 50%;
            margin: 0 auto;
            float: left;
            z-index: 10;
            .ImgContent {
                position: relative;
                .bgImg {
                    height: 692px;
                    width: 535px;
                    background-color: $primary-color;
                    border-radius: 32px;
                    opacity: 0.3;
                }
                .imgDesktop {
                    height: 692px;
                    width: 535px;
                    position: absolute;
                    top: 45px;
                    left: 72px;
                    border-radius: 32px;
                }

                .img-mobile {
                    display: none;
                }
            }
        }

    }


}

@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-5{
    margin:0 auto;
    margin-top: 80px;

    .container {
        height: 750px;
        margin: 0 auto;
        position: relative;
        padding-top: 40px;

        /* MOBILE */
        @include media('<=phone') {
            width: 100%;
            margin: 0 auto;
            display: block;
            margin-left: 10px;
            .h1{
                font-size: 2.2rem !important;
            }
        }
        /* END MOBILE */

        /* TABLET */
        @include media('>phone','<=tablet') {
            width: 100%;
            margin: 0 auto;
            display: block;
            margin-left: 10px;
            .h1{
                font-size: 2.2rem !important;
            }
        }
        /* END TABLET */

        .content {
            width: 33.33%;
            float: left;
            margin-top:45px;
            .icon {
                width: 122px;
                height: 122px;
                background-color: $primary-color-opacity;
                border-radius: 100%;
                float: left;
                margin-right:15px;
                @include centerItems();
            }
            /* MOBILE */
            @include media('<=phone') {
                width: 100%;
                margin: 0 auto;
                display: block;
                margin-top: 30px;

                .icon {
                    width: 100px;
                    height: 100px;
    
                    .p{
                        font-size: 18px;
                    }

                    img {
                        width: 50px !important;
                    }
                }
            }
            /* END MOBILE */

            /* TABLET */
            @include media('>phone','<=tablet') {
                width: 100%;
                margin: 0 auto;
                display: block;
                margin-top: 30px;

                .icon {
                    width: 100px;
                    height: 100px;
    
                    .p{
                        font-size: 18px;
                    }

                    img {
                        width: 50px !important;
                    }
                }
            }
            /* END TABLET */
        }
    }
}
@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-8{
    margin-top: 80px;
    background-color: $secondry-color-opacity;
    margin:0 auto;

    .container {
        height: auto;
        margin: 0 auto;
        padding-top: 40px;

        .h3 {
            color: $secondry-color;
        }

        .content {
            height: 250px;
            margin-top: 60px;
            background-color: white;
            border-radius: 64px;
            padding: 20px;
            margin-left: 5px;
            .icon {
                width: 122px;
                height: 122px;
                background-color: white;
                border-radius: 100%;
                margin-right:15px;
                @include centerItems();
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
            }

            .text {
                .p {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
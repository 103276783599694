@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-4{
    height: auto;
    margin:0 auto;
    background-color: $primary-color;
    padding: 50px;
    @include media('<=991px') {
        margin-top: 300px;
        padding: 30px 0px;
        height: auto;
    }

    .container{
        margin: 0 auto;
        @include centerItems();
        .content-1 {
            @include centerItems();
        }
        .content-2 {
            @include centerItems();
        }
        .rightSide{
            .btn-custom {
                padding: 1rem 1em;
                position: relative;
                background-color:white !important;
                color: black !important;
                font-size: 22px;

                &:hover{
                    background-color: #F5F5F5 !important;
                    cursor: pointer;
                }
            } 
        }
    }
}
@mixin centerItems {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupInput {
    label {
        font-size: 16px;
        font-family: $font;
    }
    .input{
        border: 0.2px solid #D0D0D0;
        padding: 5px;
        border-radius: 100px;
        margin-top: 15px;
        background-color: #FAFAFA;
        .iconInput {
            width: 59px;
            height: 59px;
            float: left;
            border-radius: 100px;
            background-color: rgba($color: #EFEFEF, $alpha: 1.0);
            @include centerItems();
        }
        input {
             height: 59px;
             font-size: 14px;
             border: none;
             outline: none;
             width: 70%;
             background-color: #FAFAFA;
             margin-left: 10px;
             font-family: $font;
        }

    }


}